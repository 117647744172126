<style lang="scss">
</style>
<template>
    <div>
        <order-list ref="orderList" type="trip"></order-list>
    </div>

</template>

<script type="text/ecmascript-6">
import OrderList from '../orderList/index'

export default {
    name: 'tripList',
    //定义模版数据
    data() {
        let data
        return {
            data
        }
    },
    components: {OrderList},
    activated() {
        this.$refs['orderList'] && this.$refs['orderList'].activated && this.$refs['orderList'].activated();
    }
}
</script>
